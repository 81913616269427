import {
  gameAbbotMeeple,
  gameArrowFlights,
  gameAxeSwing,
  gameBeerBottle,
  gameBoots,
  gameBubbles,
  gameChestArmor,
  gameClockwork,
  gameCoinflip,
  gameCrystalBars,
  gameDragonShield,
  gameFireflake,
  gameFizzingFlask,
  gameFoldedPaper,
  gameGladius,
  gameHealing,
  gameHeartBottle,
  gameMoonClaws,
  gameRoundBottomFlask,
  gameRoundPotion,
  gameShieldEchoes,
  gameSpanner,
  gameSpiralBottle,
  gameStoneSphere,
  gameStoneTablet,
  gameSwordClash,
  gameSwordWound,
  gameTransparentSlime,
  gameTunePitch,
  gameWaveStrike,
  gameWineBottle,
} from '@ng-icons/game-icons';
import {
  tablerBible,
  tablerBlob,
  tablerBrain,
  tablerBubble,
  tablerCarrot,
  tablerCell,
  tablerCoins,
  tablerCross,
  tablerHeartBroken,
  tablerHelpHexagon,
  tablerHorseshoe,
  tablerMoon,
  tablerMusic,
  tablerProng,
  tablerShield,
  tablerSword,
  tablerSwords,
  tablerWall,
  tablerWand,
  tablerWood,
} from '@ng-icons/tabler-icons';

export function usedContentIcons(): Record<string, string> {
  return {
    tablerCoins,
    tablerSword,
    tablerHeartBroken,
    tablerBrain,
    tablerHelpHexagon,
    tablerBubble,
    tablerWood,
    tablerBlob,
    tablerCarrot,
    tablerBible,
    tablerWall,
    tablerShield,
    tablerSwords,
    tablerCross,
    tablerMoon,
    tablerMusic,
    tablerWand,
    tablerCell,
    tablerProng,
    tablerHorseshoe,
    gameRoundPotion,
    gameStoneTablet,
    gameFoldedPaper,
    gameShieldEchoes,
    gameAbbotMeeple,
    gameClockwork,
    gameHeartBottle,
    gameBeerBottle,
    gameCrystalBars,
    gameHealing,
    gameWaveStrike,
    gameAxeSwing,
    gameCoinflip,
    gameBubbles,
    gameStoneSphere,
    gameArrowFlights,
    gameSwordClash,
    gameSwordWound,
    gameMoonClaws,
    gameFireflake,
    gameRoundBottomFlask,
    gameFizzingFlask,
    gameWineBottle,
    gameSpiralBottle,
    gameTransparentSlime,
    gameTunePitch,
    gameGladius,
    gameSpanner,
    gameDragonShield,
    gameBoots,
    gameChestArmor,
  };
}
